import React, { useState } from "react";
import { useIntl } from "gatsby-plugin-intl";

import "../styles/pages/Contact.scss";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Input from "../components/Input";

const DEFAULT_CONTACT_FORM = {
  name: "",
  lastName: "",
  email: "",
  phone: "",
  text: ""
};

export default function Contact() {
  const intl = useIntl();
  const [form, setForm] = useState(DEFAULT_CONTACT_FORM);

  const handleInput = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(form);
      setForm(DEFAULT_CONTACT_FORM);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "contact-seo" })} />
      <main className="contact">
        <section className="contact__content">
          <form onSubmit={handleSubmit} className="contact__form">
            <h1 className="contact__title">{intl.formatMessage({ id: "contact-title" })}</h1>
            <div className="contact__form-grid">
              <Input
                type="text"
                id="name"
                text={intl.formatMessage({ id: "contact-form-name" })}
                value={form.name}
                onChange={handleInput}
                black
              />
              <Input
                type="text"
                id="lastName"
                text={intl.formatMessage({ id: "contact-form-lastname" })}
                value={form.lastName}
                onChange={handleInput}
                black
              />
              <Input
                type="email"
                id="email"
                text={intl.formatMessage({ id: "contact-form-email" })}
                value={form.email}
                onChange={handleInput}
                black
              />
              <Input
                type="tel"
                id="phone"
                text={intl.formatMessage({ id: "contact-form-phone" })}
                value={form.phone}
                onChange={handleInput}
                black
              />
              <Input
                type="text"
                id="text"
                text={intl.formatMessage({ id: "contact-form-message" })}
                value={form.text}
                onChange={handleInput}
                black
              />
            </div>
            <button type="submit" className="contact__form-submit">
              {intl.formatMessage({ id: "contact-form-submit" })}
            </button>
          </form>
        </section>
      </main>
    </Layout>
  )
}
